import React from 'react'
import ProjectionGrid from "../components/projection/ProjectionGrid";
import qs from "qs";
import ProjectionTileImage from "../components/projection/ProjectionTileImage";

const ProjectionTestPage = () => {
  const {layout, factor} = qs.parse(window.location.search, {ignoreQueryPrefix: true});
  let usedLayout: "absolute" | "relative" = "relative";
  if (layout === "absolute") {
    usedLayout = "absolute"
  }

  return (
    <ProjectionGrid
      slot1={<ProjectionTileImage/>}
      slot2={<ProjectionTileImage/>}
      slot3={<ProjectionTileImage/>}
      slot4={<ProjectionTileImage/>}
      slot5={<ProjectionTileImage/>}
      slot6={<ProjectionTileImage/>}
      slot7={<ProjectionTileImage/>}
      slot8={<ProjectionTileImage/>}
      layout={usedLayout}
      factor={Number(factor) || undefined}
    />
  )
}

export default ProjectionTestPage;
