/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react';
import ProjectionTileImage from '../components/projection/ProjectionTileImage';
import { css } from '@emotion/react';
import qs from "qs";
import useWindowSize from '@rooks/use-window-size';
import { posterWidth, posterHeight } from '../utils/constants';

const VideoScreenPage = () => {
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const { innerWidth, innerHeight } = useWindowSize();
  const items: ReactNode[] = [];
  const gap = params.gap ? Number(params.gap) : 0
  const verticalPadding = params.vpad ? Number(params.vpad) : 0
  const horizontalPadding = params.hpad ? Number(params.hpad) : 0


  if (!innerWidth || !innerHeight) {
    return <div>Could not get window width</div>
  }

  // Calculate the aspect ratio
  const aspectRatio = posterHeight / posterWidth;

  // Calculate the width and height of each image
  const imageWidth = (innerWidth / 4);
  const imageHeight = imageWidth * aspectRatio;

  for (let i = 0; i < 8; i++) {
    items.push(
      <div css={css`
      display: flex;
      align-items: center;
      justify-content: center;
      `}>
        <div css={css`
      width: ${imageWidth - gap}px;
      height: ${imageHeight - gap}px;
    `}>
          <ProjectionTileImage />
        </div>
      </div>
    )
  }

  return (
    <div css={css`
    width: 100vw;
    height: 100vh;
    background: black;
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    padding: ${horizontalPadding}px ${verticalPadding}px;
    `}>
      {items}
    </div>
  );
};

export default VideoScreenPage;