/** @jsxImportSource @emotion/react */
import React, {ReactNode} from 'react'
import {css} from "@emotion/react";
import qs from "qs";

interface Props {
  slot1: ReactNode,
  slot2: ReactNode,
  slot3: ReactNode,
  slot4: ReactNode,
  slot5: ReactNode,
  slot6: ReactNode,
  slot7: ReactNode,
  slot8: ReactNode,
  layout: "relative" | "absolute",
  factor?: number
}

const ProjectionGrid = ({
                          slot1,
                          slot2,
                          slot3,
                          slot4,
                          slot5,
                          slot6,
                          slot7,
                          slot8,
                          layout = "relative",
                          factor = 1
                        }: Props) => {
  const {borderWidth} = qs.parse(window.location.search, {ignoreQueryPrefix: true});
  const border = Number(borderWidth) || 0;
  const unit = layout === "relative" ? "fr" : "px";
  const gridCSS = css`
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    width: calc(100vw - ${border * 2}px);
    height: calc(100vh - ${border * 2}px);
    border: ${border}px solid black;
    display: grid;
    grid-template-columns:
    [col1-start] ${389 * factor}${unit} 
    [col1-end] ${121 * factor}${unit} 
    [col2-start] ${389 * factor}${unit} 
    [col2-end] ${121 * factor}${unit} 
    [col3-start] ${389 * factor}${unit}
    [col3-end] ${121 * factor}${unit} 
    [col4-start] ${389 * factor}${unit} [col4-end];
    grid-template-rows:
    ${54 * factor}${unit} 
    [row1-start] ${485 * factor}${unit} 
    [row1-end] ${121 * factor}${unit} 
    [row2-start] ${485 * factor}${unit}
    [row2-end] ${54 * factor}${unit};

    & > div {
      min-width: 0;
      min-height: 0;
    }
  `

  return (
    <div css={gridCSS}>
      {/* FIRST ROW */}
      <div css={css`
        grid-column: col1-start / col1-end;
        grid-row: row1-start / row1-end;
      `}>
        {slot1}
      </div>
      <div css={css`
        grid-column: col2-start / col2-end;
        grid-row: row1-start / row1-end;
      `}>
        {slot2}
      </div>
      <div css={css`
        grid-column: col3-start / col3-end;
        grid-row: row1-start / row1-end;
      `}>
        {slot3}
      </div>
      <div css={css`
        grid-column: col4-start / col4-end;
        grid-row: row1-start / row1-end;
      `}>
        {slot4}
      </div>

      {/*SECOND ROW*/}
      <div css={css`
        grid-column: col1-start / col1-end;
        grid-row: row2-start / row2-end;
      `}>
        {slot5}
      </div>
      <div css={css`
        grid-column: col2-start / col2-end;
        grid-row: row2-start / row2-end;
      `}>
        {slot6}
      </div>
      <div css={css`
        grid-column: col3-start / col3-end;
        grid-row: row2-start / row2-end;
      `}>
        {slot7}
      </div>
      <div css={css`
        grid-column: col4-start / col4-end;
        grid-row: row2-start / row2-end;
      `}>
        {slot8}
      </div>
    </div>
  )
}

export default ProjectionGrid;
