/** @jsxImportSource @emotion/react */
import React from 'react'
import {css} from "@emotion/react";
import {borderWidth} from "../../styling/theme";

const LoadingAnimation = () => {
  const width = 600;
  const height = 600;
  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
      `}
    >
      <circle
        fill="none"
        stroke="white"
        strokeWidth={borderWidth}
        vectorEffect="non-scaling-stroke"
        strokeDasharray="300 100"
        cx={width / 2}
        cy={width / 2}
        r={100}
        css={css`
          transform-origin: center;
          animation: spin 2s linear infinite;
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      />
    </svg>
  )
}

export default LoadingAnimation;
