/**
 * Global font definition for use in styling and rendering pipeline
 */
import { css } from "@emotion/react";
import gravityStaticSrc from "../assets/ABCGravity-Normal.otf";
import gravitySrc from "../assets/ABCGravityVariable.ttf";
import cironSrc from "../assets/Ciron0.4.3-VariableVF.ttf";
import g2CiaoSchrillSrc from "../assets/G2Ciao-Shrill.otf";
import g2CiaoSilentSrc from "../assets/G2Ciao-Silent.otf";
import licariaSrc from "../assets/LicariaTrial-Book.otf";
import neurealSrc from "../assets/Neureal-Regular.woff";
import mobilSrc from "../assets/Mobil-Black.woff";

export const fontSources: Record<string, string> = {
  "Gravity": gravitySrc,
  "Licaria": licariaSrc,
  "Ciron": cironSrc,
  "G2-Ciao-Shrill": g2CiaoSchrillSrc,
  "G2-Ciao-Silent": g2CiaoSilentSrc,
  "GravityStatic": gravityStaticSrc,
  "Neureal": neurealSrc,
  "Mobil": mobilSrc
};

export const fontStyles = Object.entries(fontSources).map(([name, src]) => css`
@font-face {
  font-family: ${name};
  src: url(${src});
}
`);
