/** @jsxImportSource @emotion/react */
import React from 'react'
import {useRecoilState} from "recoil";
import {popupState} from "../../state/general";
import {css} from "@emotion/react";
import useWindowSize from "@rooks/use-window-size";
import {fontSize} from "../../styling/theme";
import CloseButton from "../submission/CloseButton";

interface Props {

}

const Popup: React.FC<Props> = () => {
  const {innerHeight} = useWindowSize();
  const [popup, setPopup] = useRecoilState(popupState);
  const src = popup ?? ""
  return (
    <div
      css={css`
        transition: all .5s ease;
        pointer-events: none;
        opacity: 0;
        transform: scale(0.5);
        border-radius: 500px;

        ${popup && css`
          pointer-events: all;
          opacity: 1;
          transform: scale(1);
          border-radius: 0;
        `}

        background: black;
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: ${innerHeight}px;
        z-index: 100;
        font-size: ${fontSize.main};
        display: flex;
        flex-direction: column;
      `}
    >
      <div css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      `}>
        <CloseButton onClick={() => {
          setPopup(null);
        }}></CloseButton>
      </div>
      <iframe
        src={src}
        sandbox={src?.endsWith(".pdf") ? undefined : ""}
        css={css`
          border: 0;
          flex-grow: 2;
        `}
      />
    </div>
  )
}

export default Popup;
