/** @jsxImportSource @emotion/react */
import React, {MouseEventHandler} from 'react'
import {css, SerializedStyles} from "@emotion/react";
import {borderWidth} from "../../styling/theme";

interface Props {
  addCSS?: SerializedStyles,
  onClick?: MouseEventHandler,
  color?: string
}

const CloseButton: React.FC<Props> = ({
                                        addCSS,
                                        onClick,
                                        color = "white"
                                      }) => {
  return (
    <svg
      width="71"
      height="71"
      viewBox="0 0 71 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        cursor: pointer;
        transition: all 0.25s ease;

        &:hover {
          transform: rotate(90deg);
        }

        ${addCSS}
      `}
      onClick={onClick}
    >
      <g>
        <line x1="52.3257" y1="53.7403" x2="16.9704" y2="18.385" stroke={color} strokeWidth={borderWidth}
              vectorEffect="non-scaling-stroke"/>
        <line x1="52.3257" y1="53.7403" x2="16.9704" y2="18.385" stroke={color} strokeWidth={borderWidth}
              vectorEffect="non-scaling-stroke"/>
        <line x1="53.7399" y1="18.3848" x2="18.3846" y2="53.7402" stroke={color} strokeWidth={borderWidth}
              vectorEffect="non-scaling-stroke"/>
        <line x1="53.7399" y1="18.3848" x2="18.3846" y2="53.7402" stroke={color} strokeWidth={borderWidth}
              vectorEffect="non-scaling-stroke"/>
      </g>
    </svg>
  )
}

export default CloseButton;
