/** @jsxImportSource @emotion/react */
import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RawSVGRenderer from "./pages/RawSVGRenderer";
import { css, Global } from '@emotion/react';
import { featureSettings, fontFamily, fontSize, margin, variationSettings } from "./styling/theme";
import * as Sentry from "@sentry/react";

import LoadingAnimation from "./components/common/LoadingAnimation";
import Button from "./components/common/Button";
import ProjectionPage from "./pages/ProjectionPage";
import Popup from "./components/common/Popup";
import { fontStyles } from './utils/fonts';
import VideoScreenPage from './pages/VideoScreenPage';
import NotFoundPage from './pages/NotFoundPage';

const LicensePage = React.lazy(() => import('./pages/LicensePage'));
const LandingPage = React.lazy(() => import('./pages/LandingPage'));
const LearnMorePage = React.lazy(() => import('./pages/LearnMorePage'));
const ProjectionTestPage = React.lazy(() => import('./pages/ProjectionTestPage'));
const GalleryPage = React.lazy(() => import('./pages/GalleryPage'));
const Editor = React.lazy(() => import('./pages/Editor'));

const App = () => {
  return (
    <>
      {/* Global Styles */}
      <Global
        styles={css`
          ${fontStyles}

          body {
            margin: 0;
          }

          html {
            background: black;
            color: white;
            font-family: ${fontFamily};
            font-variation-settings: ${variationSettings};
            font-feature-settings: ${featureSettings};
            font-size: ${fontSize.main}px;
            transition: background-color .2s ease;
          }

          .hint-tooltip {
            opacity: 1 !important;
            font-family: ${fontFamily} !important;
            font-variation-settings: ${variationSettings};
            font-feature-settings: ${featureSettings};
            font-size: ${fontSize.info}px !important;
          }

          a {
            color: white;
          }

          a:hover {
            text-decoration: none;
          }

          a:visited {
            color: white;
          }

        `}
      />

      {/* App Root */}
      <Sentry.ErrorBoundary fallback={({ error, componentStack, resetError }) => (
        (
          <div
            css={css`
              position: fixed;
              top: 0;
              left: 0;
              width: 100vw;
              height: 100vh;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            `}
          >
            <h1>An error has occurred!</h1>
            <p>{error.toString()}</p>
            <Button onClick={resetError}>
              Try again
            </Button>
            <div
              css={css`
                width: 100%;
                height: 1rem;
              `}
            />
            <Button onClick={() => window.location.reload()}>
              Refresh
            </Button>
          </div>
        )
      )}>
        <React.Suspense fallback={<LoadingAnimation />}>
          {/*Info popup*/}
          <Popup />
          <BrowserRouter>
            <Routes>
              {/* PUBLIC */}
              <Route path="/editor" element={<Editor />} />
              <Route path="/info" element={<LearnMorePage />} />
              <Route path="/gallery" element={<GalleryPage />} />
              <Route path="/oss-licenses" element={<LicensePage />} />
              <Route path="/" element={<LandingPage />} />

              {/* INTERNAL */}
              <Route path="/render-raw" element={<RawSVGRenderer />} />
              <Route path="/projection-test" element={<ProjectionTestPage />} />
              <Route path="/projection" element={<ProjectionPage />} />
              <Route path="/screen-display" element={<VideoScreenPage />} />

              {/* NOT FOUND */}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </BrowserRouter>
        </React.Suspense>
      </Sentry.ErrorBoundary>
    </>
  )
}

export default App;
