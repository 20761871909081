import { capitalize, clamp } from "lodash";
import { color } from "chroma.ts"
import {  ClipboardEventHandler, DragEventHandler } from "react";
import { CanvasElement } from "../state/editorAdvanced";
import { backgroundElementID } from "./constants";
import { position } from "caret-pos";
import { getImgURL } from "./api";

export const duplicateElement = (element: Element) => {
  return <Element>element.cloneNode(true)
}


/**
 * Move a value towards a target by changing another value (parameter), which directly affects the value.
 *
 * For example: We want to change the height of a text container (value) to a certain target height,
 * by modifying the fontSize of the text in the container (parameter).
 *
 * @param targetValue
 * @param valueGetter
 * @param paramGetter
 * @param paramSetter
 * @param paramMin
 * @param paramMax
 * @param initialStepSize
 */
export const fitValueWithParam = (
  targetValue: number,
  valueGetter: () => number,
  paramGetter: () => number,
  paramSetter: (param: number) => void,
  paramMin?: number,
  paramMax?: number,
  initialStepSize: number = 10
) => {
  // TODO look up rate of change

  const originalValue = valueGetter();
  const originalParam = paramGetter();
  const initialStep = targetValue < originalValue ? -initialStepSize : initialStepSize;
  // const initialStep = initialStepSize;


  // Disregard initial out of bounds steps
  if (paramMin && originalParam + initialStep < paramMin) {
    console.log('Value fitter out of bounds: initial step less than min.');
    return false
  }
  if (paramMax && originalParam + initialStep > paramMax) {
    console.log('Value fitter out of bounds: initial step more than max.');
    return false
  }

  paramSetter(originalParam + initialStep);

  const initialValue = valueGetter();
  const initialDifference = initialValue - originalValue;
  const targetDifference = targetValue - originalValue;
  const differenceFactor = targetDifference / initialDifference;
  const resultStep = differenceFactor * initialStep;

  if (paramMin !== undefined && paramMax !== undefined) {
    paramSetter(clamp(originalParam + resultStep, paramMin, paramMax));
  } else {
    paramSetter(originalParam + resultStep);
  }
  // console.log("Fitted param to ", paramGetter())
  // console.log({
  //     initialStep,
  //     originalParam,
  //     targetValue,
  //     originalValue,
  //     initialValue,
  //     initialDifference,
  //     targetDifference,
  //     differenceFactor,
  //     resultStep
  // })
  return true
}

export const placeCaretAtEnd = (el: HTMLElement) => {
  el.focus();
  if (typeof window.getSelection != "undefined"
    && typeof document.createRange != "undefined") {
    const range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    const sel = window.getSelection();
    if (sel) {
      sel.removeAllRanges();
      sel.addRange(range);
    }
  } else { // @ts-ignore
    if (typeof document.body.createTextRange != "undefined") {// @ts-ignore
      const textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
  }
}

export const getBlendedColor = (inputColor: string) => {
  const baseWhite = [255, 255, 255];
  const inputRGB = color(inputColor).rgb();
  const outputRGB = inputRGB.map((_, i) => baseWhite[i] - inputRGB[i]);
  return color(outputRGB).hex()
}

export const targetIsLink = (target: EventTarget) => {
  const domNode = <HTMLElement>target;
  if (domNode.nodeName === "A") {
    return true;
  }
  return false
}

export const reMap = (value: number, istart: number, istop: number, ostart: number, ostop: number) => {
  return ostart + (ostop - ostart) * ((value - istart) / (istop - istart));
}

export const prettyLayerName = (name: string) => {
  switch (name.toLocaleLowerCase()) {
    case "rect":
      return "Rectangle";
    case "path":
      return "Shape";
    case "foreignobject":
      return "Text"
  }
  return capitalize(name)
}

export const generateCanvasStyle = (list: CanvasElement[], render = false) => {
  // Base static styles
  let output = `
  foreignObject{
    overflow: visible;
  }
  `
  list.forEach((item) => {
    if (item.id === backgroundElementID) return;

    output += `
#${item.id}{
  mix-blend-mode: ${item.blendMode};
  ${item.typeface ? `
  font-family: ${item.typeface};
  ` : ''}
}
  ${item.type === "text" ? `
 #${item.id} div{
    color: ${item.color};
    ${item.typeSettings ? `
    font-size: ${item.typeSettings.size}px;
    letter-spacing: ${item.typeSettings.tracking}px;
    line-height: ${item.typeSettings.leading};
    ${item.typeSettings.stylisticSets.length > 0 ? `
    font-feature-settings: ${item.typeSettings.stylisticSets.map(ss => ` "${ss}" 1`).join(',')};
    ` : ''}
    font-variation-settings: ${Object.entries(item.typeSettings.variationSettings).map(([key, value]) => `"${key}" ${value}`).join(",")};
    ` : ''}
    ${(item.typeface === "G2-Ciao-Silent" && item.typeSettings?.alternateCut) ? 'font-family: G2-Ciao-Shrill;' : ''}
  }
  ` : ''}
  
  ${item.type === "image" && item.image ? `
  #${item.id} div{
    background: url("${getImgURL(item.image.File, "large")}");
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position-x: ${item.imagePosX}%;
    background-position-y: ${item.imagePosY}%;
  }
  
  #${item.id} div.full-source{
      background: url("${render ? item.image.File.attributes.url : getImgURL(item.image.File)}");
      background-size: cover;
      background-position-x: ${item.imagePosX}%;
      background-position-y: ${item.imagePosY}%;
  }
  ` : ''}
    `
  })

  return output
}

export const deleteElement = (id: string, elementList: CanvasElement[]) => {
  const list = elementList.slice();
  const targetIndex = elementList.findIndex(i => i.id === id)
  const item = elementList[targetIndex];
  if (window.confirm(`Delete ${prettyLayerName(item.el?.nodeName || "this")} Element?`)) {
    if (item) {
      item.el?.remove();
    }
    list.splice(targetIndex, 1);
  }
  return list
}

export const selectContents = (targetEL: HTMLElement) => {
  window.getSelection()?.selectAllChildren(targetEL)
}

export const clearSelection = () => {
  if (window.getSelection()?.empty) {  // Chrome
    window.getSelection()?.empty();
  } else if (window.getSelection()?.removeAllRanges) {  // Firefox
    window.getSelection()?.removeAllRanges();
  }
}

const clipboardIntercept: ClipboardEventHandler = (e) => {
  e.preventDefault()
  return false
}

const dropIntercept: DragEventHandler = (e) => {
  e.preventDefault()
  return false
}

export const contentEditableProps = {
  contentEditable: true,
  spellCheck: false,
  onPaste: clipboardIntercept,
  onDrop: dropIntercept
}

export const getCaret = (target: HTMLElement) => {
  const sel = window.getSelection()
  if (sel && sel.rangeCount > 0) {
    return position(target).pos
  }
}

export const serializePoster = (advancedMode: boolean) : string => {
  const posterEl = advancedMode
    ? window.document.querySelector("#svgcanvas")
    : window.document.querySelector("#poster-canvas-root");
  if (!posterEl) {
    alert("Error capturing poster canvas, your device may not support the required features. Please try a different device or browser.");
    throw new Error("Error capturing poster canvas!");
  }
  const posterFragment = posterEl.cloneNode(true) as HTMLElement;
  // Switch to full res image source on simple image embeds
  Array.from(posterFragment.querySelectorAll("image"))
    .forEach((imageItem) => {
      if (imageItem.hasAttribute("data-full-image-source")) {
        imageItem.setAttribute("href", imageItem.getAttribute("data-full-image-source") as string)
      }
    })
  // Switch to full res image on advanced image embeds (class full-source has full bg image)
  Array.from(posterFragment.querySelectorAll("div.advanced-img-element"))
    .forEach((imageNode) => {
      const imageItem = imageNode as HTMLDivElement;
      imageItem.classList.add('full-source');
    })
  // Remove SVGEdit UI elements
  posterFragment.querySelector("#selectorParentGroup")?.remove()
  posterFragment.querySelector("#canvashadow")?.remove()

  return posterFragment.outerHTML;
}