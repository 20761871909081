/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {

  return (
    <div css={css`
    background: black;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    `}>
      <p>
      Page not found: {window.location.pathname}
      </p>
      <p>
        <Link to="/">Go to home</Link>
      </p>
    </div>
  );
};

export default NotFoundPage;