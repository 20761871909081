import React, {useEffect, useState} from 'react'
import qs from "qs";
import {api} from "../utils/api";

const RawSVGRenderer: React.FC = () => {
    const [data, setData] = useState("")
    useEffect(() => {
        const params = qs.parse(window.location.search, {ignoreQueryPrefix: true});
        if (!params.id || !params.key) {
            location.href = "/";
            return
        }
        api.get('posters/' + params.id, {
            headers: {
                'Authorization': `Bearer ${params.key}`
            }
        })
            .then(res => res.data)
            .then(res => {
                console.log({res})
                setData(res.data.attributes.SVGdata)
            })
    }, [])

    return (
        <div dangerouslySetInnerHTML={{
            __html: data
        }}>
        </div>
    )
}

export default RawSVGRenderer;
