/**
 * SVG to PNG rendering
 */
import { CanvasElement } from "../state/editorAdvanced";
import { fontFamily as defaultFontFamily } from "../styling/theme";
import { imgURL } from "./api";
import { posterHeight, posterWidth } from "./constants";
import { fontSources } from "./fonts";
import { generateCanvasStyle } from "./functions";

const RENDER_SIZE_FACTOR = 2;
const RENDER_WIDTH = posterWidth * RENDER_SIZE_FACTOR;
const RENDER_HEIGHT = posterHeight * RENDER_SIZE_FACTOR
const DEBUG_DOWNLOAD_SVG = false;

/////////////////////////////////////////
/// HELPER UTILITIES
/////////////////////////////////////////

const flatten = (node: Element): Element[] => {
  const children = Array.from(node.children)
  return children.concat(children.flatMap(flatten))
}

const blobToBase64 = (blob: Blob): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader;
  reader.onerror = reject;
  reader.onload = () => {
    if (typeof reader.result === 'string') {
      resolve(reader.result);
    } else {
      throw new Error("Blob could not be converted to base64, returned non-string result")
    }
  };
  reader.readAsDataURL(blob);
});

/////////////////////////////////////////
/// RENDERING FUNCTIONS
/////////////////////////////////////////

/**
 * Takes an SVG element and creates a clone with all the assets inlined
 * Assets are the typefaces and images used in the SVG
 * @param svg 
 * @param advancedElementList
 */
export const createInlineSVG = async (
  svg: SVGElement,
  advancedElementList: CanvasElement[] = []
): Promise<SVGElement> => {
  // Clone the SVG
  const clone = svg.cloneNode(true) as SVGElement;
  // Remove SVGEdit UI elements
  clone.querySelector("#selectorParentGroup")?.remove()
  clone.querySelector("#canvashadow")?.remove()

  clone.setAttribute("width", RENDER_WIDTH.toString());
  clone.setAttribute("height", RENDER_HEIGHT.toString());
  const elements = flatten(clone);
  const defsEl = clone.querySelector('defs');
  if (!defsEl) throw new Error("SVG does not have a defs element");
  const fonts = new Set<string>()
  fonts.add(defaultFontFamily.split(",")[0]); // always used for credits text

  for (const e of elements) {
    console.log('Render, processing element', e);
    // Collect unique fonts (except default font)
    // -- from regular element computed style
    const computedStyle = window.getComputedStyle(e)
    if (computedStyle['fontFamily'] && computedStyle['fontFamily'] !== defaultFontFamily) {
      fonts.add(computedStyle['fontFamily'])
    }
    // -- from inline style
    if (e instanceof HTMLDivElement && e.style['fontFamily'] && e.style['fontFamily'] !== defaultFontFamily) {
      fonts.add(e.style['fontFamily'])
    }
    // -- from original element computed style (advanced editor)
    // if (e instanceof SVGGElement && e.hasAttribute("id")) {
    //   const originalElement = svg.querySelector(`#${e.getAttribute("id")}`)
    //   if (originalElement) {
    //     const originalComputedStyle = window.getComputedStyle(originalElement)
    //     if (originalComputedStyle['fontFamily'] && originalComputedStyle['fontFamily'] !== defaultFontFamily) {
    //       fonts.add(originalComputedStyle['fontFamily'])
    //     }
    //   }
    // }
    // Inline basic images
    if (e instanceof SVGImageElement) {
      const image = e as SVGImageElement;
      const source = image.getAttribute('data-full-image-source') || image.getAttribute('href');
      if (!source) {
        throw new Error(`Image ${image} does not have a source`)
      }
      const imageRes = await fetch(source);
      if (!imageRes.ok) {
        throw new Error(`Could not fetch image ${source}`)
      }
      const imageData = await imageRes.blob();
      const imageBase64 = await blobToBase64(imageData);
      image.setAttribute('href', imageBase64);
    }
    // Add advanced image class that contains full source image
    if (e.classList.contains('advanced-img-element')) {
      e.classList.add("full-source")
    }
  }

  // Add fonts from advanced editor elements
  for (const element of advancedElementList) {
    if (element.typeface) {
      fonts.add(element.typeface)
    }
  }

  // For advanced mode, convert stylesheet to inline image assets
  const styleEl = clone.querySelector('style');
  if (svg.id === "svgcanvas" && styleEl) {
    // Download all images into the image url attribute of the element list
    const convertedElements = []
    for (const el of advancedElementList) {
      if (el.type === "image" && el.image) {
        const imageRes = await fetch(imgURL(el.image.File.attributes.url));
        if (!imageRes.ok) {
          throw new Error(`Could not fetch image ${imgURL(el.image.File.attributes.url)}`)
        }
        const imageData = await imageRes.blob();
        const imageBase64 = await blobToBase64(imageData);
        convertedElements.push({
          ...el,
          image: {
            ...el.image,
            File: {
              ...el.image.File,
              attributes: {
                ...el.image.File.attributes,
                url: imageBase64
              }
            }
          }
        })
      } else {
        convertedElements.push(el)
      }
    }
    // regenerate stylesheet with the inlined image assets
    styleEl.textContent = generateCanvasStyle(convertedElements, true);
  }


  console.log("Fonts collected:", fonts)

  // Collect fonts and inline them into a style tag
  const fontFaceStyle = document.createElement('style')
  fontFaceStyle.setAttribute('type', 'text/css')
  for (const font of fonts) {
    if (!fontSources[font]) {
      console.error(`Font ${font} not in font source map`)
      continue
    }
    const fontRes = await fetch(fontSources[font]);
    const fontData = await fontRes.blob();
    const fontBase64 = await blobToBase64(fontData);
    fontFaceStyle.innerHTML += `
      @font-face {
        font-family: ${font};
        src: url("${fontBase64}");
      }
    `
  }
  defsEl.insertBefore(fontFaceStyle, defsEl.firstElementChild);
  return clone
}

/**
 * Takes an SVG element and renders it to a canvas
 * The SVG element is cloned in the process and all assets are inlined
 * This function takes 2 seconds to complete because of enforeced delay
 * to fix rendering issues on Safari
 * @param svg - SVG element to render
 * @param advancedElementList - Advanced editor element list, used to extract advanced styles & fonts
 */
export const renderToCanvas = async (
  svg: SVGElement,
  advancedElementList: CanvasElement[] = []
) => {
  const target = await createInlineSVG(svg, advancedElementList);
  const canvas = document.createElement('canvas');
  const svgAsXML = (new XMLSerializer).serializeToString(target);

  // for debugging purposes, download svg xml to file
  if (DEBUG_DOWNLOAD_SVG) {
    const link = document.createElement('a');
    link.download = "poster.svg";
    link.href = 'data:image/svg+xml;utf8,' + encodeURIComponent(svgAsXML);
    link.click();
  }

  const loaderImage = new Image();
  loaderImage.width = RENDER_WIDTH;
  loaderImage.height = RENDER_HEIGHT;

  return new Promise<HTMLCanvasElement>((resolve, reject) => {
    loaderImage.onload = () => {
      canvas.width = RENDER_WIDTH;
      canvas.height = RENDER_HEIGHT;
      const ctx = canvas.getContext('2d');
      if (!ctx) throw new Error("Could not get canvas context");
      // draw background color
      ctx.fillStyle = "#000000";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "#ffffff";
      ctx.drawImage(loaderImage, 0, 0, canvas.width, canvas.height);
      /**
       * But why the timeout?
       * Well, on safari the canvas is not rendered correctly the first time.
       * I think this is because the image / fonts are not loaded yet, although 
       * they should be, since they are just base64 strings.
       * Waiting a moment before drawing seems to help, ideally I would like to
       * respond to some event that tells me that the fonts or images are loaded.
       */
      setTimeout(() => {
        ctx.drawImage(loaderImage, 0, 0, canvas.width, canvas.height);
        setTimeout(() => {
          ctx.drawImage(loaderImage, 0, 0, canvas.width, canvas.height);
          setTimeout(() => resolve(canvas), 100);
        }, 100)
      }, 1000)
    }
    loaderImage.onerror = reject;
    loaderImage.src = 'data:image/svg+xml;utf8,' + encodeURIComponent(svgAsXML);
  }
  );
}

export const canvasToDownloadLink = (canvas: HTMLCanvasElement) => {
  const link = document.createElement('a');
  link.download = "poster.png";
  link.href = canvas.toDataURL("image/png");
  return link;
}