import React from 'react'
import ReactDOM from 'react-dom'
import { RecoilRoot } from "recoil";
import App from "./App";
import * as Sentry from "@sentry/react";
import { Context as ResponsiveContext } from 'react-responsive'
import { ResizeObserver as Polyfill } from '@juggle/resize-observer';
import { canvasToDownloadLink, renderToCanvas } from './utils/rendering';

window.ResizeObserver = window.ResizeObserver || Polyfill;

Sentry.init({
  dsn: "https://325d5dc30b5c7ed7f595720d304f7d79@o1143671.ingest.sentry.io/4506752208994304",
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// For SSR:
if (typeof window === undefined) {
  ReactDOM.render(
    <React.StrictMode>
      <RecoilRoot>
        <ResponsiveContext.Provider value={{ width: 1200 }}>
          <App />
        </ResponsiveContext.Provider>
      </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('root')
  )
} else {

  ReactDOM.render(
    <React.StrictMode>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('root')
  )

  // @ts-ignore For debugging
  window.renderToCanvas = renderToCanvas
  // @ts-ignore For debugging
  window.canvasToDownloadLink = canvasToDownloadLink
}
