import {css} from "@emotion/react";

export const colors = {
  primary: '#FFFFFF',
  secondary: '#000000'
}

export const fontFamily = "Ciron, sans-serif";

export const variationSettings = "'wght' 100, 'FLVR' 0.00";

export const featureSettings = `"ss01" 1`;

export const fontSize = {
  cutDemo: 60,
  typefaceSelect: 28,
  title: 30,
  main: 24,
  info: 18,
  subTitle: 16,
  credits: 10
}

export const fontSizeMobile = {
  title: 25,
  main: 18
}

export const margin = {
  main: 25,
  medium: 20,
  galleryBar: 15,
  small: 10,
  tiny: 5
}

export const marginMobile = {
  main: 15,
  small: 10,
  tiny: 2.5
}

export const selectedBR = 25;

export const cardBorderRadius = 49;

export const easing = "cubic-bezier(0.5,-0.38, 0.43, 1.06)";

export const borderWidth = 2;

export const hideScrollbar = css`
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`
export const textShadowOutline = (size: number, color: string) => `-${size}px -${size}px 0 ${color},  
    ${size}px -${size}px 0 ${color},
    -${size}px ${size}px 0 ${color},
     ${size}px ${size}px 0 ${color}`
