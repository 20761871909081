import {atom, selector} from "recoil";
import qs from "qs";
import {api} from "../utils/api";
import {StrapiImage} from "../strapi";

// EDITOR GENERAL

export const forceGuideState = atom({
  key: "forceGuide",
  default: false,
})

export const showIntroState = atom({
  key: 'introState',
  default: true
})

export const showInfoState = atom({
  key: 'infoState',
  default: false
})

export const showSubmissionState = atom({
  key: 'showSubmissionState',
  default: false
})

export const advancedModeState = atom({
  key: 'advancedModeState',
  default: false
})

// IMAGES

export interface ImageItem {
  Title: string,
  Credit: string,
  Location?: string,
  Year?: string,
  File: StrapiImage,
  Category: string,
  id: number
}

const imageQueryString = qs.stringify(
  {
    populate: {
      images: {
        populate: ["File"],
        pagination: {
          pageSize: 200
        }
      }
    },
  },
  {encodeValuesOnly: true}
);

export const imagesQuery = selector({
  key: 'imagesQuery',
  get: async ({get}) => {
    const categories: string[] = [];
    const images: ImageItem[] = [];
    const res = await api.get(`image-categories?${imageQueryString}`)
    res.data.data.forEach((category: any) => {
      if (category.attributes.images.data?.length > 0) {
        categories.push(category.attributes.Label);
        category.attributes.images.data.forEach((img: any) => {
          images.push({
            Title: img.attributes.Title,
            Credit: img.attributes.Credit,
            Location: img.attributes.Location,
            Year: img.attributes.Year,
            id: img.id,
            File: img.attributes.File.data,
            Category: category.attributes.Label
          })
        })
      }
    })
    return ({
      categories,
      images
    });
  }
})

export const imageListState = selector({
  key: 'imageListState',
  get: ({get}) => {
    return get(imagesQuery).images;
  }
})

export const imageCategoriesState = selector({
  key: 'imageCategoriesState',
  get: ({get}) => {
    return get(imagesQuery).categories;
  }
})

export const popupState = atom<string | null>({
  key: "popupState",
  default: null
})
