import axios from "axios";
import placeholderImageSrc from "../assets/placeholderImg.svg";
import {StrapiImage} from "../strapi";

export const api = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_ENDPOINT + "/api/",
  // timeout: 1000,
  // headers: {
  //     'Authorization': `Bearer ${import.meta.env.VITE_API_TOKEN}`
  // }
});

export const imgURL = (path?: string) => path
  ? (`${import.meta.env.VITE_BACKEND_ENDPOINT}${path}`)
  : placeholderImageSrc
;

export const getImgURL = (image: StrapiImage, size? : ("large" | "medium" | "small" | "thumbnail")) => {
  if(size && image.attributes.formats && image.attributes.formats[size]){
    return imgURL(image.attributes.formats[size].url);
  }else{
    return imgURL(image.attributes.url);
  }
}
