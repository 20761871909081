import React, {useEffect, useState} from 'react'
import qs from "qs";
import {StrapiImageAttributes} from "../../strapi";
import {api, getImgURL} from "../../utils/api";
import {random} from "lodash";
import * as Sentry from "@sentry/react";

const params = qs.parse(window.location.search, {ignoreQueryPrefix: true});
const minDelay = Number(params.minDelay) || 60000;
const maxDelay = Number(params.maxDelay) || 60000 * 5;

interface ProjectionItem {
  id: number,
  posterPNG: StrapiImageAttributes,
}

const ProjectionTileImage = () => {
  const [channel, setChannel] = useState<"a" | "b">("a");
  const [imageA, setImageA] = useState<ProjectionItem | null>(null);
  const [imageB, setImageB] = useState<ProjectionItem | null>(null);

  const onLoadA = () => {
    console.log("Channel A image loaded");
    setChannel("a");
  }

  const onLoadB = () => {
    console.log("Channel B image loaded");
    setChannel("b");
  }

  const advanceImage = async () => {
    try {
      const res = await api.get(`poster/random`);
      if (channel === "a") {
        setImageB(res.data);
      } else {
        setImageA(res.data);
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      console.warn('Advance image has failed with the above error')
    }
    setTimeout(advanceImage, random(minDelay, maxDelay));
  }

  useEffect(() => {
    const delay = random(0, 1000);
    console.log(`Delaying for ${delay}ms...`)
    setTimeout(advanceImage, delay);
  }, [])

  return (
    <div style={{
      background: "white",
      color: "black",
      fontSize: 20,
      left: 0,
      top: 0,
      position: "relative",
      width: "100%",
      height: "100%"
    }}>
      {!imageA && !imageB && "Initial tile delay, please wait."}
      {imageA &&
        <img
          src={getImgURL({attributes: imageA.posterPNG, id: imageA.id})}
          alt=""
          onLoad={onLoadA}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            opacity: channel === "a" ? 1 : 0.001,
            zIndex: channel === "a" ? 2 : 1
          }}
        />
      }
      {imageB &&
        <img
          src={getImgURL({attributes: imageB.posterPNG, id: imageB.id})}
          alt=""
          onLoad={onLoadB}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            opacity: channel === "b" ? 1 : 0.001,
            zIndex: channel === "b" ? 2 : 1
          }}
        />
      }
    </div>
  )
}

export default ProjectionTileImage;
