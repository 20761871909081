import qs from "qs";

const staticQueryParams = qs.parse(window.location.search, {ignoreQueryPrefix: true})
export const posterWidth = 800;
export const posterHeight = 1000;
export const defaultPosterMargin = 20;
export const exportWidth = posterWidth * 2;
export const exportHeight = posterHeight * 2;
export const gravityMinWidth = 1;
export const gravityMaxWidth = 8;
export const backgroundElementID = "__background-el"
export const SVGNamespace = "http://www.w3.org/2000/svg"
// embed lock
export const embedLock = staticQueryParams.mode === "embed";

export const typography = {
  leading: {
    default: 1,
    min: 0,
    max: 5,
    step: 0.01
  },
  tracking: {
    default: 0,
    min: -20,
    max: 80,
    step: 1
  },
  size: {
    default: 80,
    min: 10,
    max: 600,
    step: 1
  },
  cironFlavor: {
    default: 0,
    min: 0,
    max: 100,
    step: 1
  },
  cironWeight: {
    default: 25,
    min: 25,
    max: 280,
    step: 1
  },
  gravityWidth: {
    default: 5,
    min: 1,
    max: 8,
    step: 0.01
  }
}
