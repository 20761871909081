/** @jsxImportSource @emotion/react */
import {css, SerializedStyles} from '@emotion/react'
import React, {MouseEventHandler, ReactNode} from 'react'
import {
  borderWidth,
  colors,
  featureSettings,
  fontFamily,
  fontSize,
  fontSizeMobile,
  variationSettings
} from "../../styling/theme";
import {mobile} from "../../styling/media";


interface Props {
  children: ReactNode,
  onClick: MouseEventHandler,
  addCSS?: SerializedStyles,
  disabled?: boolean,
  growMobile?: boolean
}

const Button: React.FC<Props> = ({
                                   children,
                                   onClick,
                                   addCSS,
                                   disabled = false,
                                   growMobile = false
                                 }) => {
  return (
    <button
      css={css`
        border: ${borderWidth}px solid ${colors.primary};
        background: ${colors.secondary};
        color: ${colors.primary};
        font-family: ${fontFamily};
        cursor: pointer;
        font-variation-settings: ${variationSettings};
        font-feature-settings: ${featureSettings};
        font-size: ${fontSize.main}px;
        border-radius: 37px;
        font-weight: normal;
        height: 100%;
        width: 100%;
        padding: 15px 15px;
        user-select: none;

        &:hover {
          background: ${colors.primary};
          color: ${colors.secondary};
        }

        ${addCSS}
        ${disabled && css`
          opacity: 0.33;
          cursor: not-allowed;

          &:hover {
            background: black;
            color: white;
          }
        `} @media ${mobile.query} {
          font-size: ${fontSizeMobile.main}px;
          padding: 5px 5px;
          
          ${!growMobile && 'height: 2em;'}
          &:hover, &:focus {
            background: ${colors.secondary};
            color: ${colors.primary};
          }

          &:active {
            background: ${colors.primary};
            color: ${colors.secondary};
          }
        }
      `}
      onClick={disabled ? undefined : onClick}
    >
      {children}
    </button>
  )
}

export default Button;
